<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
});

const orderNumberId = computed(() => {
  const orderNumber = props?.data?.item?.orderNumber;
  return orderNumber ?? props?.data?.item?.orderId ?? "";
});
</script>

<template>
  <span>{{ orderNumberId }}</span>
</template>

<style lang="scss" scoped></style>
